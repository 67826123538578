<template>
  <div class="container vld-parent">
    <b-row>
      <loading
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"
      ></loading>
      <b-card v-if="errorLoading">
        <b-alert variant="danger" show="true">
          <h4 class="alert-heading my-0">Message not found</h4>
        </b-alert>
        <p>There was an error loading the message.</p>
        <b-button variant="danger" size="sm" @click="loadImages"
          >Click here to try again</b-button
        >
      </b-card>

      <b-card class="w-100" v-if="message && !errorLoading">
        <b-row>
          <b-col>
            <b-card-title>{{
              messageId ? "Message Details" : "Create New Message"
            }}</b-card-title>
          </b-col>
          <b-col>
            <b-button
              @click="goBackToMessages"
              variant="outline-primary"
              size="sm"
              class="float-md-right "
              >&lt; Back To All Messages</b-button
            ></b-col
          >
        </b-row>

        <b-row>
          <b-col lg="6" md="6" sm="12">
            <b-form-group label="Category" label-for="txtCategory">
              <div
                class="w-100 customer-multiselect"
                :class="
                  validateState('messageCategoryId') == false
                    ? 'is-invalid'
                    : ''
                "
              >
                <multiselect
                  v-model="selectedCategory"
                  @select="selected"
                  :options="categories"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Select category"
                  label="category"
                  track-by="messageCategoryId"
                  :id="messageCategoryId"
                  :preselect-first="true"
                >
                </multiselect>

                <b-form-invalid-feedback
                  id="txtCategory-live-feedback"
                  :state="validateState('messageCategoryId')"
                  >Category is required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="6" md="6" sm="12">
            <b-form-group label="Title" label-for="txtTitle">
              <b-form-input
                id="txtTitle"
                name="txtTitle"
                v-model="message.title"
                :class="validateState('title') == false ? 'is-invalid' : ''"
                aria-describedby="txtTitle-live-feedback"
              ></b-form-input>

              <b-form-invalid-feedback id="txtTitle-live-feedback"
                >Title is required.</b-form-invalid-feedback
              >
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div
              :class="validateState('message') == false ? 'is-invalid2' : ''"
            >
              <vue-editor
                :editorToolbar="customToolbar"
                v-model="message.message"
              ></vue-editor>
            </div>

            <b-form-invalid-feedback
              id="txtMessage-live-feedback"
              :state="validateState('message')"
              >Message is required.</b-form-invalid-feedback
            >
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col lg="6" md="6" sm="12">
            <b-form-group>
              <label>Send To </label>

              <i
                class="fa fa-question-circle text-primary bottom-align-text ml-1"
                v-b-popover.hover="deliverySitePopoverText"
                title="Send To"
              ></i>

              <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Select Users"
                label="email"
                track-by="id"
                :id="id"
                :preselect-first="false"
                :custom-label="customLabel"
              >
                <template slot="clear" slot-scope="props">
                  <div
                    class="multiselect__clear"
                    v-if="selectedUsers.length"
                    @mousedown.prevent.stop="selectedUsers = []"
                  ></div>
                </template>

                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span
                      >{{ props.option.firstName }}
                      {{ props.option.lastName }}</span
                    >
                    <br />
                    <span
                      ><small>{{ props.option.email }}</small></span
                    ><br />
                    <span
                      ><small>{{ props.option.customerName }}</small></span
                    >
                  </div>
                </template>

                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} users selected</span
                  ></template
                >
              </multiselect>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <span v-if="selectedUsers.length > 0"
              >Message will be sent to:</span
            >
            <span v-else>
              Message will be sent to all users.
            </span>

            <b-list-group>
              <b-list-group-item
                v-for="item in selectedUsers"
                :key="item.id"
                class="d-flex justify-content-between align-items-center border-0"
              >
                {{ item.firstName }} {{ item.lastName }} ({{ item.email }})

                <b-button variant="link" @click="removeUser(item.id)"
                  >&nbsp;<i class="fa fa-user-times text-danger"></i
                ></b-button>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="!messageId">
          <b-col cols="12">
            <b-button variant="primary" @click="showMessageCreateConfirm()"
              >Create Message</b-button
            >

            <b-button variant="outline-danger" class="ml-3" @click="cancel"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-row>

    <b-modal
      v-model="showUserDeleteModal"
      title="Please confirm your message before saving."
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      size="lg"
      @hide="closeModal()"
    >
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <b>Category: </b
          >{{ selectedCategory ? selectedCategory.category : "" }}
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" md="6" sm="12">
          <b>Title: </b> {{ message.title }}
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <br />
          <b>Message:</b>

          <div v-html="message.message"></div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <span v-if="selectedUsers.length > 0">Message will be sent to:</span>
          <span v-else>
            Message will be sent to all users.
          </span>

          <b-list-group>
            <b-list-group-item
              v-for="item in selectedUsers"
              :key="item.id"
              class="d-flex justify-content-between align-items-center border-0"
            >
              {{ item.firstName }} {{ item.lastName }} ({{ item.email }})

              <b-button variant="link" @click="removeUser(item.id)"
                >&nbsp;<i class="fa fa-user-times text-danger"></i
              ></b-button>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <div slot="modal-footer" class="w-100">
        <b-button
          class="float-right"
          variant="outline-danger"
          @click="closeModal()"
          >No, Keep editing.</b-button
        >
        <b-button
          class="float-right ml-2 mr-2"
          variant="primary"
          @click="confirmMessageCreate()"
          >Yes, Create</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/RepositoryFactory";
const MessagesRepository = RepositoryFactory.get("messagesRepository");

const UserRepository = RepositoryFactory.get("users");

import { VueEditor } from "vue2-editor";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: [],
  components: { VueEditor, Multiselect, Loading },
  data() {
    return {
      isLoading: false,
      errorLoading: false,
      selectedCategory: null,
      message: {
        title: "",
        message: "",
        messageCategoryId: null,
        userIds: []
      },
      showUserDeleteModal: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["link"],
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        [{ color: [] }, { background: [] }]
      ],
      categories: [],
      users: [],
      selectedUsers: []
    };
  },
  async created() {},
  async mounted() {
    this.isLoading = true;
    this.errorLoading = false;

    try {
      var requestData = {
        skip: 0,
        take: 1000000
      };
      this.categories = await MessagesRepository.getCategories(requestData);

      if (this.messageId) {
        this.message = await MessagesRepository.getById(this.messageId);
        this.selectedCategory = this.categories.find(
          c => c.messageCategoryId == this.message.messageCategoryId
        );
      }

      this.users = (await UserRepository.get()).data.results;
    } catch (error) {
      this.errorLoading = true;
    }

    this.isLoading = false;
  },
  methods: {
    goBackToMessages() {
      this.$router.push({
        name: "admin-messages"
      });
    },
    selected(selectedOption) {
      this.message.messageCategoryId = selectedOption.messageCategoryId;
    },
    async confirmMessageCreate() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;

        this.message.userIds = this.selectedUsers.map(u => u.id);

        await MessagesRepository.create(this.message);

        this.goBackToMessages();
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.message[name];

      return $dirty ? !$error : null;
    },
    cancel() {
      this.$router.push({
        name: "admin-messages"
      });
    },
    showMessageCreateConfirm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.showUserDeleteModal = true;
      }
    },
    closeModal() {
      this.showUserDeleteModal = false;
    },
    removeUser(userId) {
      this.selectedUsers = this.selectedUsers.filter(e => e.id != userId);
    },
    customLabel(user) {
      return `${user.email} – ${user.customerName}`;
    }
  },
  computed: {
    messageId() {
      return this.$route.params.id;
    },
    deliverySitePopoverText() {
      return "You can select individual users to send this message to. If no users are selected it will be sent to all users in the system.";
    }
  },
  validations: {
    message: {
      messageCategoryId: {
        required
      },
      title: {
        required
      },
      message: {
        required
      }
    }
  },
  destroyed() {}
};
</script>

<style scoped>
.list-group-item {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
